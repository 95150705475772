// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    overflow: hidden;
}
.modal {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    margin: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    max-height: 80vh;
}

.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.input-group {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.select {
    width: 100%;
}

.file-input {
    display: none;
}

.choose-file-button {
    display: inline-block;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 10px;
}

.submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-loader {

}
`, "",{"version":3,"sources":["webpack://./src/FileUploadModal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;AAEA","sourcesContent":["body {\n    overflow: hidden;\n}\n.modal {\n    background: #fff;\n    border-radius: 8px;\n    padding: 20px;\n    width: 400px;\n    margin: auto;\n}\n\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.75);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.modal-content {\n    max-height: 80vh;\n}\n\n.loader-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 200px;\n}\n\n.input-group {\n    margin-bottom: 15px;\n}\n\n.label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: bold;\n}\n\n.select {\n    width: 100%;\n}\n\n.file-input {\n    display: none;\n}\n\n.choose-file-button {\n    display: inline-block;\n    cursor: pointer;\n    background-color: #007bff;\n    color: white;\n    padding: 10px 15px;\n    border-radius: 5px;\n    margin-top: 10px;\n}\n\n.submit-button {\n    background-color: #007bff;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.modal-loader {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
