// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
    background-color: #f3f4f6;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
    max-width: 500px;
    margin: 20px auto;
}

h2 {
    color: #333;
    margin-bottom: 10px;
}

p {
    color: #666;
    font-size: 14px;
}

label {
    display: block;
    margin-bottom: 10px;
    color: #333;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    width: 97%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

textarea {
    height: 100px;
    resize: none;
}


button {
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 20px;
}

button:hover {
    background-color: #004494;
}

p:last-of-type {
    font-size: 12px;
    color: #999;
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,qCAAqC;IACrC,8BAA8B;IAC9B,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,WAAW;AACf;;AAEA;;;;IAII,UAAU;IACV,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;;AAGA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["form {\n    background-color: #f3f4f6;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0,0,0,0.1);\n    font-family: Arial, sans-serif;\n    max-width: 500px;\n    margin: 20px auto;\n}\n\nh2 {\n    color: #333;\n    margin-bottom: 10px;\n}\n\np {\n    color: #666;\n    font-size: 14px;\n}\n\nlabel {\n    display: block;\n    margin-bottom: 10px;\n    color: #333;\n}\n\ninput[type=\"text\"],\ninput[type=\"email\"],\ninput[type=\"tel\"],\ntextarea {\n    width: 97%;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    margin-top: 5px;\n}\n\ntextarea {\n    height: 100px;\n    resize: none;\n}\n\n\nbutton {\n    background-color: #0056b3;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    display: block;\n    width: 100%;\n    margin-top: 20px;\n}\n\nbutton:hover {\n    background-color: #004494;\n}\n\np:last-of-type {\n    font-size: 12px;\n    color: #999;\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
