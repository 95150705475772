import React, {useState} from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import './FileUploadModal.css';
import ContactForm from './ContactForm';
import Loader from './Loader';

const countries = require('i18n-iso-countries');

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const FileUploadModal = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [file, setFile] = useState(null);
  const [transcribedText, setTranscribedText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false)

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "zh", label: "Chinese" },
    { value: "de", label: "German" },
    { value: "es", label: "Spanish" },
    { value: "ru", label: "Russian" },
    { value: "ko", label: "Korean" },
    { value: "fr", label: "French" },
    { value: "ja", label: "Japanese" },
    { value: "pt", label: "Portuguese" },
    { value: "tr", label: "Turkish" },
    { value: "pl", label: "Polish" },
    { value: "ca", label: "Catalan" },
    { value: "nl", label: "Dutch" },
    { value: "ar", label: "Arabic" },
    { value: "sv", label: "Swedish" },
    { value: "it", label: "Italian" },
    { value: "id", label: "Indonesian" },
    { value: "hi", label: "Hindi" },
    { value: "fi", label: "Finnish" },
    { value: "vi", label: "Vietnamese" },
    { value: "he", label: "Hebrew" },
    { value: "uk", label: "Ukrainian" },
    { value: "el", label: "Greek" },
    { value: "ms", label: "Malay" },
    { value: "cs", label: "Czech" },
    { value: "ro", label: "Romanian" },
    { value: "da", label: "Danish" },
    { value: "hu", label: "Hungarian" },
    { value: "ta", label: "Tamil" },
    { value: "no", label: "Norwegian" },
    { value: "th", label: "Thai" },
    { value: "ur", label: "Urdu" },
    { value: "hr", label: "Croatian" },
    { value: "bg", label: "Bulgarian" },
    { value: "lt", label: "Lithuanian" },
    { value: "la", label: "Latin" },
    { value: "mi", label: "Maori" },
    { value: "ml", label: "Malayalam" },
    { value: "cy", label: "Welsh" },
    { value: "sk", label: "Slovak" },
    { value: "te", label: "Telugu" },
    { value: "fa", label: "Persian" },
    { value: "lv", label: "Latvian" },
    { value: "bn", label: "Bengali" },
    { value: "sr", label: "Serbian" },
    { value: "az", label: "Azerbaijani" },
    { value: "sl", label: "Slovenian" },
    { value: "kn", label: "Kannada" },
    { value: "et", label: "Estonian" },
    { value: "mk", label: "Macedonian" },
    { value: "br", label: "Breton" },
    { value: "eu", label: "Basque" },
    { value: "is", label: "Icelandic" },
    { value: "hy", label: "Armenian" },
    { value: "ne", label: "Nepali" },
    { value: "mn", label: "Mongolian" },
    { value: "bs", label: "Bosnian" },
    { value: "kk", label: "Kazakh" },
    { value: "sq", label: "Albanian" },
    { value: "sw", label: "Swahili" },
    { value: "gl", label: "Galician" },
    { value: "mr", label: "Marathi" },
    { value: "pa", label: "Punjabi" },
    { value: "si", label: "Sinhala" },
    { value: "km", label: "Khmer" },
    { value: "sn", label: "Shona" },
    { value: "yo", label: "Yoruba" },
    { value: "so", label: "Somali" },
    { value: "af", label: "Afrikaans" },
    { value: "oc", label: "Occitan" },
    { value: "ka", label: "Georgian" },
    { value: "be", label: "Belarusian" },
    { value: "tg", label: "Tajik" },
    { value: "sd", label: "Sindhi" },
    { value: "gu", label: "Gujarati" },
    { value: "am", label: "Amharic" },
    { value: "yi", label: "Yiddish" },
    { value: "lo", label: "Lao" },
    { value: "uz", label: "Uzbek" },
    { value: "fo", label: "Faroese" },
    { value: "ht", label: "Haitian Creole" },
    { value: "ps", label: "Pashto" },
    { value: "tk", label: "Turkmen" },
    { value: "nn", label: "Nynorsk" },
    { value: "mt", label: "Maltese" },
    { value: "sa", label: "Sanskrit" },
    { value: "lb", label: "Luxembourgish" },
    { value: "my", label: "Myanmar" },
    { value: "bo", label: "Tibetan" },
    { value: "tl", label: "Tagalog" },
    { value: "mg", label: "Malagasy" },
    { value: "as", label: "Assamese" },
    { value: "tt", label: "Tatar" },
    { value: "haw", label: "Hawaiian" },
    { value: "ln", label: "Lingala" },
    { value: "ha", label: "Hausa" },
    { value: "ba", label: "Bashkir" },
    { value: "jw", label: "Javanese" },
    { value: "su", label: "Sundanese" }
  ];


  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', selectedLanguage.value);

    try {
      setIsLoading(true);
      const response = await fetch(process.env.REACT_APP_TRANSCRIBE_URL, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.status === 403) {
        setIsLoading(false);
        setIsError(true);
        alert(result.message);
        return;
      }
      setTranscribedText(result.text);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      alert('Something went wrong.\nContact the sales department.\nsales@lantec.ua');
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Modal
        isOpen={true}
        contentLabel="File Upload Modal"
        className={`modal ${isLoading ? 'modal-loader' : ''}`}
        overlayClassName="overlay"
      >
        <div className="modal-content">
          { isLoading ? (
            <div className="loader-container">
              <h2 className="modal-header-loader">Transcribing...</h2>
              <Loader />
            </div>
          ) : (
            <>
              <h2 className="modal-header">Transcribe</h2>
              <div className="input-group">
                <label className="label">Language</label>
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  options={languageOptions}
                  placeholder="Select a language"
                  className="select"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: '25px',
                      fontSize: '15px',
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                    menuList: (base) => ({
                      ...base,
                      fontSize: '12px',
                    }),
                  }}
                />
              </div>
              <div className="input-group">
                <label className="label">File Upload</label>
                <input type="file" onChange={handleFileChange} id="file-upload" className="file-input" />
                {file ? <p>{file.name}</p> : null}
                <label htmlFor="file-upload" className="choose-file-button">Choose File</label>
                {transcribedText ? (
                  <>
                    <h3>Transcribed text:</h3>
                    <p>{transcribedText}</p>
                  </>
                ) : null}
              </div>
              <button onClick={handleSubmit} className="submit-button">Submit</button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FileUploadModal;
