import {useState} from 'react';
import './ContactForm.css';

const ContactForm = ({ setIsError }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    additionalInfo: '',
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsError(false);
    console.log('Form data:', formData);
    // Add your form submission logic here
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Enter your contact details</h2>
      <p>The sales department will contact you.</p>

      <label>
        Name*
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </label>

      <label>
        E-mail*
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </label>

      <label>
        Phone*
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          placeholder="+380"
        />
      </label>
      <label>
        Additional Information About Your Needs (optional)
        <textarea
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
        />
      </label>

      <p>By registering, you agree that your personal information will be processed in accordance with the privacy policy of
        LANTEC.</p>

      <button type="submit">Contact me</button>
    </form>
  );
};

export default ContactForm;
