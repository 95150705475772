import React, { useState } from 'react';
import FileUploadModal from './FileUploadModal';
import Modal from 'react-modal';
import './App.css'; // Import the CSS file

Modal.setAppElement('#root');

const SignIn = ({ onSignIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignIn = () => {
    const name = process.env.USER_NAME;
    console.log(name);
    if (username === process.env.REACT_APP_USER_NAME && password === process.env.REACT_APP_PASSWORD) {
      onSignIn();
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="sign-in-overlay">
      <div className="sign-in-modal">
        <h2>Sign In</h2>
        <div className="input-group">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="submit-button" onClick={handleSignIn}>Sign In</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

const App = () => {
  const [signedIn, setSignedIn] = useState(false);

  return (
    <div>
      {signedIn ? <FileUploadModal /> : <SignIn onSignIn={() => setSignedIn(true)} />}
    </div>
  );
};

export default App;
